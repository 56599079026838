import { defineStore } from 'pinia'
import { useContactStore } from './index.js'
import parsePhoneNumber from 'libphonenumber-js'

export const useChatStore = defineStore('chat', {
	state: () => ({
		phone: null, // phone number
		overwriting: false, // on when manually selecting participant from the list
		telegram_username: null, // username (used in Telegram)
		name: null, // contact name
		is_group: null,
		platform: null,
		messages: null,
		group_name: null,
		participants: null,
		// the following are additional parameters not passed in the message from the browser extension
		phone_formatted: null // phone number
	}),
	actions: {
		selectParticipant(index) {
			this.overwriting = true
			this.phone = this.participants[index]?.phone
			this.telegram_username = this.participants[index]?.telegram_username
			this.name = this.participants[index]?.name
			this.formatPhone()

			const contactStore = useContactStore()
			contactStore.get({
				value: this?.phone,
				username: this?.telegram_username
			})
		},
		unselectParticipant() {
			this.overwriting = false
			this.phone = null
			this.telegram_username = null
			this.name = null
		},
		formatPhone() {
			this.phone_formatted = null
			if (this.phone) {
				this.phone_formatted = parsePhoneNumber(this.phone)?.formatInternational()
				this.phone_formatted ||= this.phone
			}
		},
		formatAllParticipantsPhoneNumbers() {
			if (this.participants?.length > 0) {
				for (let i = 0; i < this.participants.length; i++) {
					if (!this.participants[i]?.phone) {
						continue
					}
					this.participants[i].phone = parsePhoneNumber(
						this.participants[i].phone
					)?.formatInternational()
				}
			}
		},
		setParticipants(participants) {
			if (!(participants?.length > 1)) {
				this.participants = null
				return
			}
			this.participants = participants.filter(
				(participant) => participant.phone || participant.telegram_username
			)
			for (let i = 0; i < this.participants.length; i++) {
				this.participants[i].index = i
			}
			this.formatAllParticipantsPhoneNumbers()
		},
		setInner(data) {
			this.phone = data.value
			this.telegram_username = data.username
			this.name = data.name
			this.platform = data.platform
			this.is_group = data.is_group
			this.group_name = data.group_name
			this.participants = !data.is_group ? null : this.setParticipants(data.participants)
			this.formatPhone()
		},
		set(data) {
			if (
				this.overwriting &&
				(this.is_group != data.is_group || this.group_name != data.group_name)
			) {
				this.overwriting = false
			}

			if (this.somethingChanged(data) && !this.overwriting) {
				this.setInner(data)
				return true
			}

			// Still, update the list of participants if changed (more loaded)
			if (this.is_group && this.participants?.length != data.participants?.length) {
				this.setParticipants(data.participants)
			}

			return false
		},
		somethingChanged(data) {
			return (
				this.phone != data.value ||
				this.telegram_username != data.username ||
				this.name != data.name ||
				this.group_name != data.group_name ||
				this.is_group != data.is_group
			)
		}
	},
	getters: {
		selected_messages() {
			if (!this.messages?.length) {
				return []
			}
			return this.messages.filter((message) => message.selected)
		},
		phone_or_overwritten() {},
		can_display_group() {
			return this.is_group && this.participants?.length > 1
		}
	}
})
