import { defineStore } from 'pinia'
import { useDealStore, useActivitiesStore, useAuthStore } from './index.js'
import api from '@/api'

export const useContactStore = defineStore('contact', {
	state: () => ({
		contact: null,
		contact_is_from_cache: false,
		loading: false,
		creating: false,

		last_search_data: null,

		person_fields: [],
		loading_person_fields: false
	}),
	actions: {
		get(data, resync = false) {
			const authStore = useAuthStore()
			if (!authStore.user?.can_use_app) {
				// also false if user not yet loaded
				return
			}

			let has_phone = typeof data.value === 'string' && data.value.length
			let has_username = typeof data.username === 'string' && data.username.length

			if (!has_phone && !has_username) {
				return
			}

			this.loading = true
			this.contact = null
			this.contact_is_from_cache = false

			let query_strying = data.value
				? 'phone=' + encodeURIComponent(data.value)
				: 'telegram_username=' + encodeURIComponent(data.username)

			if (resync) {
				query_strying += '&resync=true'
			}

			api.get('/contact?' + query_strying)
				.then((response) => {
					this.select(response.data.id ? response.data : null)
					this.last_search_data = data
					this.loading = false
					this.contact_is_from_cache = response.headers['x-cache-status'] === 'Cached'
				})
				.catch((err) => {
					clearInterval(this.timer_loading)
					this.loading = false
				})
		},
		resync() {
			this.get(this.last_search_data, true)
		},
		select(contact_data) {
			this.contact = contact_data
			if (this.contact) {
				const activitiesStore = useActivitiesStore()
				const dealStore = useDealStore()
				activitiesStore.getActivitiesContact()
				dealStore.reset()
				dealStore.getDeals()
			} else {
				// Reset related information
				this.contact_is_from_cache = null
			}
		},
		async create(data) {
			return new Promise((resolve, reject) => {
				this.creating = true
				this.loading = true
				api.post('/contacts', data)
					.then((response) => {
						this.contact = response.data
						this.creating = false
						this.loading = false
						resolve()
					})
					.catch((err) => {
						this.creating = false
						this.loading = false
						reject()
					})
			})
		},
		async addPhone(contact_id, phone) {
			return new Promise((resolve, reject) => {
				this.loading = true
				api.post('/contacts/' + contact_id + '/phones', { phone: phone })
					.then((response) => {
						this.select(response.data)
						this.loading = false
						resolve()
					})
					.catch((err) => {
						this.loading = false
						reject()
					})
			})
		},
		async updateTelegramUsername(contact_id, telegram_username) {
			return new Promise((resolve, reject) => {
				this.loading = true
				api.put('/contacts/' + contact_id + '/telegram_username', {
					telegram_username: telegram_username
				})
					.then((response) => {
						this.contact = response.data
						this.loading = false
						resolve()
					})
					.catch((err) => {
						this.loading = false
						reject()
					})
			})
		},
		async loadPersonFields() {
			this.loading_person_fields = true
			let response = await api.get('person_fields')
			this.person_fields = response.data.filter(function (field) {
				return field.edit_flag
			})
			this.loading_person_fields = false
		}
	}
})
