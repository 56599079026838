<template>
	<div v-if="applicationStore.status.maintenance_mode" class="maintenance-mode">
		<img src="/img/pipechat-logo.png" alt="" />
		<p>{{ $t('maintenance') }}</p>
	</div>
</template>

<script>
import { useApplicationStore } from '@/stores'

export default {
	setup() {
		const applicationStore = useApplicationStore()
		return { applicationStore }
	}
}
</script>

<style scoped lang="scss">
.maintenance-mode {
	position: fixed;
	background-color: white;
	z-index: 3000;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	white-space: pre-line;
	text-align: center;
	img {
		height: 30px;
		margin-bottom: 1em;
	}
}
</style>
